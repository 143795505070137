import axios from "axios";
import router from "@/router";

export default {
	state: {
		email: {
			type: "email",
			name: "email",
			value: null,
			error: null,
			status: "default",
		},
		phone: {
			type: "tel",
			name: "phone",
			value: null,
			error: null,
			status: "default",
		},
		code: {
			name: "code",
			value: null,
			error: null,
			pattern: /^[0-9]{1,1}$/,
			disabled: true,
			numbers: [
				{
					id: 0,
					status: "default",
					value: null,
				},
				{
					id: 1,
					status: "default",
					value: null,
				},
				{
					id: 2,
					status: "default",
					value: null,
				},
				{
					id: 3,
					status: "default",
					value: null,
				},
			],
		},
		countdown: {
			show: false,
			timer: null,
		},
		validationOne: false,
		validationTwo: false,
	},
	getters: {
		getRegisterEmail: (state) => state.email,
		getRegisterPhone: (state) => state.phone,
		getRegisterCode: (state) => state.code,
		getRegisterCountdown: (state) => state.countdown,
		getRegisterValidationOne: (state) => state.validationOne,
		getRegisterValidationTwo: (state) => state.validationTwo,
	},
	mutations: {
		handleRegisterChanges(state, payload) {
			if (payload.event === "change" || payload.event === "paste" || payload.event === "input") {
				state[payload.name].value = payload.value;

				if (payload.value.match(this.state.common.patterns[payload.name])) {
					state[payload.name].status = "valid";
					state[payload.name].error = null;
				} else {
					state[payload.name].status = "invalid";
					state[payload.name].error = "Неверный формат";
				}

				state.validationOne = true;

				if (state.email.status !== "valid" || state.phone.status !== "valid") {
					state.validationOne = false;
				}
			}

			if (payload.event === "input" || payload.event === "blur") {
				if (payload.element === "code") {
					state.code.numbers.forEach((element) => {
						if (element.id == payload.id) {
							element.value = payload.value;

							if (payload.value.match(state.code.pattern) && payload.value !== "") {
								element.status = "valid";
								state.code.error = null;
							} else {
								element.status = "invalid";
								state.code.error = "Неверный формат кода";
							}
						}
					});

					let arr = [];

					for (let i = 0; i < state.code.numbers.length; i++) {
						arr[i] = state.code.numbers[i].value;
					}

					state.code.value = +arr.join("");

					state.validationTwo = state.code.numbers.every((element) => element.status === "valid");
				}
			}
		},
		startRegisterCountdown(state) {
			state.countdown.show = true;
			state.countdown.timer = 59;

			let intervalID = setInterval(() => {
				state.countdown.timer--;

				if (state.countdown.timer <= 0) {
					clearInterval(intervalID);
				}
			}, 1000);
		},
	},
	actions: {
		receiveRegisterCode(context) {
			if (context.state.validationOne) {
				context.commit("showPreloader");
				context.state.code.disabled = false;

				axios
					.post(`${this.state.common.api}/confirmations/sendCall`, {
						phone: context.state.phone.value,
					})
					.then((response) => {
						context.commit("hidePreloader");

						if (response.status === 200) {
							localStorage.setItem("register__email-value", context.state.email.value);
							localStorage.setItem("register__phone-value", context.state.phone.value);

							context.commit("startRegisterCountdown");
						}
					})
					.catch((error) => context.commit("handleError", error));
			}
		},
		sendRegisterInfo(context) {
			if (context.state.validationTwo) {
				context.commit("showPreloader");

				axios
					.post(`${this.state.common.api}/confirmations/checkCode`, {
						phone: context.state.phone.value,
						code: context.state.code.value,
					})
					.then((response) => {
						context.commit("hidePreloader");

						if (response.status === 200) router.push("/extend");
					})
					.catch((error) => context.commit("handleError", error));
			}
		},
	},
};
