import axios from 'axios';
import router from '@/router';

export default {
  state: {
    email: {
      value: null,
      code: {
        name: 'code',
        value: null,
        error: null,
        pattern: /^[0-9]{1,1}$/,
        disabled: false,
        numbers: [{
            id: 0,
            status: 'default',
            value: null,
          }, {
            id: 1,
            status: 'default',
            value: null,
          },
          {
            id: 2,
            status: 'default',
            value: null,
          },
          {
            id: 3,
            status: 'default',
            value: null,
          },
        ],
      },
      validation: false,
    },
    phone: {
      value: null,
      code: {
        name: 'code',
        value: null,
        error: null,
        pattern: /^[0-9]{1,1}$/,
        disabled: true,
        numbers: [{
            id: 0,
            status: 'default',
            value: null,
          }, {
            id: 1,
            status: 'default',
            value: null,
          },
          {
            id: 2,
            status: 'default',
            value: null,
          },
          {
            id: 3,
            status: 'default',
            value: null,
          },
        ],
      },
      validation: false,
    },
    countdown: {
      show: false,
      timer: null,
    },
  },
  getters: {
    getInviteEmail: state => state.email,
    getInvitePhone: state => state.phone,
    getInviteСountdown: state => state.countdown,
  },
  mutations: {
    updateInviteData(state, payload) {
      state.phone.value = payload.phone;
      state.email.value = payload.email;

      localStorage.setItem('invite__phone-value', state.phone.value);
    },
    handleInviteChanges(state, payload) {
      if (payload.event === 'input' || payload.event === 'blur') {
        if (payload.name === 'code') {
          state[payload.element].code.numbers.forEach(element => {
            if (element.id == payload.id) {
              element.value = payload.value;

              if (payload.value.match(state[payload.element].code.pattern) && payload.value !== '') {
                element.status = 'valid';
                state[payload.element].code.error = null;
              } else {
                element.status = 'invalid';
                state[payload.element].code.error = 'Неверный формат кода';
              }
            }
          });

          let arr = [];

          for (let i = 0; i < state[payload.element].code.numbers.length; i++) {
            arr[i] = state[payload.element].code.numbers[i].value;
          }

          state[payload.element].code.value = +arr.join('');

          state[payload.element].validation = state[payload.element].code.numbers.every(element => element.status === 'valid');
        }
      }
    },
    startInviteCountdown(state) {
      state.countdown.show = true;
      state.countdown.timer = 59;

      let intervalID = setInterval(() => {
        state.countdown.timer--;

        if (state.countdown.timer <= 0) {
          clearInterval(intervalID);
        }
      }, 1000);
    },
    resetInviteState(state, payload) {
      if (payload === 'email') {
        state.email = {
          code: {
            name: 'code',
            value: null,
            error: null,
            pattern: /^[0-9]{1,1}$/,
            disabled: false,
            numbers: [{
                id: 0,
                status: 'default',
                value: null,
              }, {
                id: 1,
                status: 'default',
                value: null,
              },
              {
                id: 2,
                status: 'default',
                value: null,
              },
              {
                id: 3,
                status: 'default',
                value: null,
              },
            ],
          },
          validation: false,
        };
      }
    },
  },
  actions: {
    fetchInvite(context) {
      let response = {
        data: {
          phone: '+7 (904) 167-19-92',
          email: 'eduard.badr@gmail.com',
        }
      };

      context.commit('updateInviteData', response.data);
    },
    confirmInviteEmail(context) {
      if (context.state.email.validation) {
        context.commit("showPreloader");

        axios
          .post(`${this.state.common.api}/mobile/users/sharedAccess/acceptRegister`, {
            data: {
              identifier: context.state.email.value,
              code: context.state.email.code.value
            }
          })
          .then((response) => {
            context.commit("hidePreloader");

            if (response.status === 200) {
              context.commit('resetInviteState', 'email');
              context.commit('startInviteCountdown');
              context.state.phone.code.disabled = false;
            }
          })
          .catch((error) => context.commit("handleError", error));
      }
    },
    confirmInvitePhone(context) {
      if (context.state.phone.validation) {
        context.commit("showPreloader");

        axios
          .post(`${this.state.common.api}/mobile/users/sharedAccess/confirmIdentifier`, {
            data: {
              identifier: context.state.phone.value,
              code: context.state.phone.code.value
            }
          })
          .then((response) => {
            context.commit("hidePreloader");

            if (response.status === 200) router.push("/set");
          })
          .catch((error) => context.commit("handleError", error));
      }
    },
    repeatInviteCall(context) {
      context.commit("showPreloader");

      setTimeout(() => {
        context.commit("hidePreloader");
        context.commit('startInviteCountdown');
      }, 3000);
    }
  },
}