import axios from "axios";

export default {
	state: {
		contacts: [],
		activeChat: {},
		message: {
			status: "default",
			error: false,
			errorMessage: "Поле не должно быть пустым",
			value: "",
			type: "text",
			attachment: {
				isHave: false,
				name: "",
				size: "",
			},
		},
	},
	getters: {
		getMessengerContacts: (state) => state.contacts,
		getMessengerActiveChat: (state) => state.activeChat,
		getMessengerMessage: (state) => state.message,
	},
	mutations: {
		setMessengerActiveChat(state, contact) {
			state.contacts.forEach((element) => (element.isActive = false));
			state.contacts.forEach((element) => {
				if (element.id == contact.id) {
					element.isActive = true;
					state.activeChat = element;
				}
			});
		},
		attachMessengerFile(state, value) {
			state.message.attachment.isHave = true;
			state.message.attachment.name = value.name;
			state.message.attachment.size = (value.size / 1000000).toFixed(2);
		},
		updateMessengerMessage(state, value) {
			state.message.value = value;
		},
		updateMessengerContacts(state, payload) {
			let userID = +localStorage.getItem("authorization__user-id");

			state.contacts = payload;

			state.contacts.forEach((contact) => {
				contact.participants.forEach((user) => {
					if (user.from_user.id !== userID) {
						contact.name = `${user.from_user.last_name} ${user.from_user.first_name}`;
						contact.type = "Должность";
					}
				});

				contact.messages.forEach((message) => {
					if (message.sender_id == userID) {
						message.type = "outgoing";
					} else {
						message.type = "incoming";
					}
				});
			});

			state.contacts[0].isActive = true;
			state.activeChat = state.contacts[0];
		},
	},
	actions: {
		fetchMessengerChats(context) {
			context.commit("showPreloader");

			let userID = +localStorage.getItem("authorization__user-id");

			axios
				.get(
					`${this.state.common.api}/chat-rooms/?filters[participants][from_user]=${userID}&populate[0]=participants,messages&populate[1]=participants.from_user,messages.statuses`,
					this.state.common.headers
				)
				.then((response) => {
					context.commit("updateMessengerContacts", response.data.data);
					context.commit("hidePreloader");
				})
				.catch((error) => {
					context.commit("handleError", error);
				});
		},
		sendMessengerMessage(context, payload) {
			context.commit("showPreloader");

			let userID = +localStorage.getItem("authorization__user-id");

			axios
				.post(
					`${this.state.common.api}/chat-messages`,
					{
						data: {
							from_room: payload.chat,
							sender_id: userID,
							created_time: payload.time,
							text: context.state.message.value,
						},
					},
					this.state.common.headers
				)
				.then(() => {
					context.state.message.value = null;
					axios
						.get(
							`${this.state.common.api}/chat-rooms/?filters[participants][from_user]=${userID}&populate[0]=participants,messages&populate[1]=participants.from_user`,
							this.state.common.headers
						)
						.then((response) => {
							context.commit("updateMessengerContacts", response.data.data);
							context.commit("hidePreloader");
						});
				})
				.catch((error) => {
					context.commit("handleError", error);
				});
		},
	},
};
