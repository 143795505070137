import axios from "axios";

export default {
	state: {
		about: {
			phone: {
				value: null,
			},
			email: {
				value: null,
			},
			email_add: {
				type: "email",
				name: "email_add",
				value: null,
				error: null,
				status: "default",
			},
			comment: {
				type: "text",
				name: "comment",
				value: null,
				error: null,
				status: "default",
			},
			cancellation: true,
			validation: false,
			extended: localStorage.getItem("profile__about-extended") === "true" ? true : false || false,
		},
		systems: {
			list: [],
			extended: localStorage.getItem("profile__systems-extended") === "true" ? true : false || false,
		},
		pay: {},
		payments: {
			badge: "1 389 руб. 09 коп.",
		},
		appearance: {
			cancellation: true,
			validation: false,
			extended: localStorage.getItem("profile__appearance-extended") === "true" ? true : false || false,
		},
		shared: {
			cancellation: true,
			validation: false,
			extended: localStorage.getItem("profile__shared-extended") === "true" ? true : false || false,
		},
		access: {
			password_old: {
				type: "text",
				name: "password_old",
				value: null,
				error: null,
				status: "default",
			},
			password_new: {
				type: "text",
				name: "password_new",
				value: null,
				error: null,
				status: "default",
			},
			password_confirm: {
				type: "text",
				name: "password_confirm",
				value: null,
				error: null,
				status: "default",
			},
			cancellation: true,
			validation: false,
			extended: localStorage.getItem("profile__access-extended") === "true" ? true : false || false,
		},
		backup: {},
	},
	getters: {
		getProfileAbout: (state) => state.about,
		getProfileSystems: (state) => state.systems,
		getProfilePay: (state) => state.pay,
		getProfilePayments: (state) => state.payments,
		getProfileAppearance: (state) => state.appearance,
		getProfileShared: (state) => state.shared,
		getProfileAccess: (state) => state.access,
	},
	mutations: {
		toggleProfileSection(state, payload) {
			state[payload].extended = !state[payload].extended;

			localStorage.setItem(`profile__${payload}-extended`, state[payload].extended);
		},
		updateProfileAbout(state, payload) {
			state.about.id = payload.id;

			if (payload.avatar) {
				state.about.avatar = payload.avatar;
				state.about.avatar.src = `${this.state.common.url}${payload.avatar.url}`;
			}

			state.about.first_name = payload.first_name;
			state.about.middle_name = payload.middle_name;
			state.about.last_name = payload.last_name;
			state.about.phone.value = payload.phone;
			state.about.email.value = payload.email;
			state.about.email_add.value = payload.email_add;
			state.about.comment.value = payload.comment;
			state.about.contacts = payload.contacts;

			state.about.contacts.forEach((contact) => {
				contact.first_name = {
					value: contact.first_name,
					error: null,
					status: "default",
				};

				contact.middle_name = {
					value: contact.middle_name,
					error: null,
					status: "default",
				};

				contact.last_name = {
					value: contact.last_name,
					error: null,
					status: "default",
				};

				contact.comment = {
					value: contact.comment,
					error: null,
					status: "default",
				};

				contact.phone = {
					value: contact.phone,
					error: null,
					status: "default",
				};

				contact.email = {
					value: contact.email,
					error: null,
					status: "default",
				};

				contact.changed = false;
			});
		},
		updateProfileSystems(state, payload) {
			state.systems.list = payload;

			state.systems.list.forEach((system) => {
				if (system.state === 0) {
					system.state = {
						value: "disabled",
						text: "выключена",
					};
				}

				if (system.state === 1) {
					system.state = {
						value: "enabled",
						text: "включена",
					};
				}

				if (system.state === 2) {
					system.state = {
						value: "error",
						text: "ошибка",
					};
				}

				if (system.state === 3) {
					system.state = {
						value: "active",
						text: "работает",
					};
				}

				if (system.state === 4) {
					system.state = {
						value: "inactive",
						text: "не работает",
					};
				}
			});
		},
		updateProfilePay(state, payload) {
			state.pay = payload;
			state.pay.extended = false;
		},
		updateProfileCards(state, payload) {
			state.pay = payload;

			state.pay.forEach((card) => {
				card.number = `**** **** **** ${card.number.substr(-4)}`;
			});

			state.pay.forEach((card) => {
				card.month = card.month < 10 ? `0${card.month}` : `${card.month}`;
			});

			state.pay.forEach((card) => {
				card.year = `${card.year}`.substr(-2);
			});
		},
		updateProfileAppearance(state, payload) {
			if (payload === "dark") state.appearance.dark = true;
			else state.appearance.dark = false;

			if (state.appearance.dark) this.commit("changeTheme", "dark");
			else this.commit("changeTheme", "light");

			this.commit("setTheme");
		},
		updateProfileShared(state, payload) {
			if (payload.shared) {
				state.shared.users = payload.shared;
				state.shared.issuingAccess = payload.issuingAccess;

				state.shared.users.forEach((user) => {
					user.first_name = {
						value: user.first_name,
						error: null,
						status: "default",
					};

					user.middle_name = {
						value: user.middle_name,
						error: null,
						status: "default",
					};

					user.last_name = {
						value: user.last_name,
						error: null,
						status: "default",
					};

					user.status = {
						value: user.status,
						error: null,
						status: "default",
					};

					user.comment = {
						value: user.comment,
						error: null,
						status: "default",
					};

					user.phone = {
						value: user.phone,
						error: null,
						status: "default",
					};

					user.email = {
						value: user.email,
						error: null,
						status: "default",
					};

					user.edit = false;

					let systems = JSON.parse(JSON.stringify(state.systems.list));

					for (let i = 0; i < systems.length; i++) {
						systems[i].shared = false;

						for (let j = 0; j < user.systems.length; j++) {
							if (systems[i].id === user.systems[j].id) {
								systems[i].shared = true;
							}
						}
					}

					user.systems = systems;
				});
			}
		},

		handleProfileChanges(state, payload) {
			function isEmpty(obj) {
				for (let key in obj) {
					return false;
				}
				return true;
			}

			if (payload.event === "change" || payload.event === "paste" || payload.event === "input") {
				if (payload.section === "about") {
					if (isEmpty(state.backup.about)) {
						state.backup.about = JSON.parse(JSON.stringify(state.about));
					}

					if (payload.contacts) {
						state.about.contacts.forEach((contact) => {
							if (contact.id === payload.id) {
								contact[payload.name].value = payload.value;
								contact.changed = true;

								if (payload.value.match(this.state.common.patterns[payload.name])) {
									contact[payload.name].status = "valid";
									contact[payload.name].error = null;
								} else {
									contact[payload.name].status = "invalid";
									contact[payload.name].error = "Неверный формат";
								}
							}
						});
					} else {
						state.about[payload.name].value = payload.value;

						if (payload.value.match(this.state.common.patterns[payload.name])) {
							state.about[payload.name].status = "valid";
							state.about[payload.name].error = null;
						} else {
							state.about[payload.name].status = "invalid";
							state.about[payload.name].error = "Неверный формат";
						}
					}

					state.about.cancellation = false;
					state.about.validation = true;

					if (state.about.email_add.status === "invalid" || state.about.comment.status === "invalid") {
						state.about.validation = false;
					}

					if (state.about.contacts && state.about.contacts.length > 0) {
						state.about.contacts.forEach((contact) => {
							for (let key in contact) {
								if (contact[key].status === "invalid") state.about.validation = false;
							}
						});
					}
				}

				if (payload.section === "appearance") {
					if (isEmpty(state.backup.appearance)) {
						state.backup.appearance = JSON.parse(JSON.stringify(state.appearance));
					}

					if (payload.value) {
						state.appearance.dark = true;
						this.commit("changeTheme", "dark");
					} else {
						state.appearance.dark = false;
						this.commit("changeTheme", "light");
					}

					this.commit("setTheme");

					state.appearance.cancellation = false;
					state.appearance.validation = true;
				}

				if (payload.section === "shared") {
					if (isEmpty(state.backup.shared)) {
						state.backup.shared = JSON.parse(JSON.stringify(state.shared));
					}

					if (payload.edit) {
						state.shared.users.forEach((user) => {
							if (user.id === payload.user) {
								user.edit = true;
							}
						});
					}

					if (payload.users) {
						state.shared.users.forEach((user) => {
							if (user.id === payload.id) {
								user[payload.name].value = payload.value;
								user.changed = true;

								if (payload.value.match(this.state.common.patterns[payload.name])) {
									user[payload.name].status = "valid";
									user[payload.name].error = null;
								} else {
									user[payload.name].status = "invalid";
									user[payload.name].error = "Неверный формат";
								}
							}
						});

						state.shared.cancellation = false;
						state.shared.validation = true;
					}

					if (payload.name === "checkbox") {
						state.shared.users.forEach((user) => {
							if (user.id === payload.user) {
								user.systems.forEach((system) => {
									if (system.id === payload.system) {
										system.shared = payload.value;
									}
								});
							}
						});
						state.shared.cancellation = false;
						state.shared.validation = true;
					}

					if (state.shared.users && state.shared.users.length > 0) {
						state.shared.users.forEach((user) => {
							for (let key in user) {
								if (user[key].status === "invalid") state.shared.validation = false;
							}
						});
					}
				}

				if (payload.section === "access") {
					if (isEmpty(state.backup.access)) {
						state.backup.access = JSON.parse(JSON.stringify(state.access));
					}
					state.access[payload.name].value = payload.value;

					if (payload.name === "password_new" || payload.name === "password_confirm") {
						if (payload.name === "password_new") {
							if (!payload.value.match(this.state.common.patterns[payload.name])) {
								state.access[payload.name].status = "invalid";
								state.access[payload.name].error = "Не менее 6 символов, строчные и заглавные буквы, цифры";
							} else if (
								payload.value.match(this.state.common.patterns[payload.name]) &&
								payload.value !== state.access["password_confirm"].value &&
								state.access["password_confirm"].value !== null
							) {
								state.access[payload.name].status = "invalid";
								state.access[payload.name].error = "Пароли не совпадают";
							} else {
								state.access[payload.name].status = "valid";
								state.access[payload.name].error = null;
							}
						}

						if (payload.name === "password_confirm") {
							if (!payload.value.match(this.state.common.patterns[payload.name])) {
								state.access[payload.name].status = "invalid";
								state.access[payload.name].error = "Не менее 6 символов, строчные и заглавные буквы, цифры";
							} else if (
								payload.value.match(this.state.common.patterns[payload.name]) &&
								payload.value !== state.access["password_new"].value
							) {
								state.access[payload.name].status = "invalid";
								state.access[payload.name].error = "Пароли не совпадают";
							} else {
								state.access[payload.name].status = "valid";
								state.access[payload.name].error = null;
							}
						}

						if (
							state.access["password_new"].value === state.access["password_confirm"].value &&
							state.access["password_new"].value.match(this.state.common.patterns[payload.name]) &&
							state.access["password_confirm"].value.match(this.state.common.patterns[payload.name])
						) {
							state.access["password_new"].status = "valid";
							state.access["password_new"].error = null;
							state.access["password_confirm"].status = "valid";
							state.access["password_confirm"].error = null;
						}
					} else {
						if (payload.value.match(this.state.common.patterns.password)) {
							state.access[payload.name].status = "valid";
							state.access[payload.name].error = null;
						} else {
							state.access[payload.name].status = "invalid";
							state.access[payload.name].error = "Неверный формат";
						}
					}

					state.access.cancellation = false;
					state.access.validation = true;

					if (
						state.access.password_old.status !== "valid" ||
						state.access.password_new.status !== "valid" ||
						state.access.password_confirm.status !== "valid"
					) {
						state.access.validation = false;
					}
				}
			}
		},

		resetProfileState(state, payload) {
			if (payload === "about") {
				state.about.email_add.status = "default";
				state.about.email_add.error = null;

				state.about.comment.status = "default";
				state.about.comment.error = null;

				state.about.cancellation = true;
				state.about.validation = false;

				state.backup.about = {};
			}

			if (payload === "appearance") {
				state.appearance.cancellation = true;
				state.appearance.validation = false;

				state.backup.appearance = {};
			}

			if (payload === "shared") {
				state.shared.cancellation = true;
				state.shared.validation = false;

				state.backup.shared = {};
			}
			if (payload === "access") {
				state.access.password_old.status = "default";
				state.access.password_old.error = null;
				state.access.password_old.value = null;

				state.access.password_new.status = "default";
				state.access.password_new.error = null;
				state.access.password_new.value = null;

				state.access.password_confirm.status = "default";
				state.access.password_confirm.error = null;
				state.access.password_confirm.value = null;

				state.access.cancellation = true;
				state.access.validation = false;

				state.backup.access = {};
			}
		},
		cancelProfileChanges(state, payload) {
			if (payload === "about") {
				state.about.email_add.status = "default";
				state.about.email_add.error = null;
				state.about.email_add.value = state.backup.about.email_add.value;

				state.about.comment.status = "default";
				state.about.comment.error = null;
				state.about.comment.value = state.backup.about.comment.value;

				state.about.contacts.forEach((contact, index) => {
					contact.first_name = {
						value: state.backup.about.contacts[index].first_name.value,
						error: null,
						status: "default",
					};

					contact.middle_name = {
						value: state.backup.about.contacts[index].middle_name.value,
						error: null,
						status: "default",
					};

					contact.last_name = {
						value: state.backup.about.contacts[index].last_name.value,
						error: null,
						status: "default",
					};

					contact.comment = {
						value: state.backup.about.contacts[index].comment.value,
						error: null,
						status: "default",
					};

					contact.phone = {
						value: state.backup.about.contacts[index].phone.value,
						error: null,
						status: "default",
					};

					contact.email = {
						value: state.backup.about.contacts[index].email.value,
						error: null,
						status: "default",
					};

					contact.changed = false;
				});

				state.about.cancellation = true;
				state.about.validation = false;

				state.backup.about = {};
			}

			if (payload === "appearance") {
				state.appearance.dark = state.backup.appearance.dark;

				if (state.appearance.dark) this.commit("changeTheme", "dark");
				else this.commit("changeTheme", "light");

				this.commit("setTheme");

				state.appearance.cancellation = true;
				state.appearance.validation = false;

				state.backup.appearance = {};
			}

			if (payload === "shared") {
				state.shared = state.backup.shared;
				state.shared.cancellation = true;
				state.shared.validation = false;

				state.backup.shared = {};
			}

			if (payload === "access") {
				state.access.password_old.status = "default";
				state.access.password_old.error = null;
				state.access.password_old.value = null;

				state.access.password_new.status = "default";
				state.access.password_new.error = null;
				state.access.password_new.value = null;

				state.access.password_confirm.status = "default";
				state.access.password_confirm.error = null;
				state.access.password_confirm.value = null;

				state.access.cancellation = true;
				state.access.validation = false;

				state.backup.access = {};
			}
		},
		clearProfileState(state) {
			state.about = {
				phone: {
					value: null,
				},
				email: {
					value: null,
				},
				email_add: {
					type: "email",
					name: "email_add",
					value: null,
					error: null,
					status: "default",
				},
				comment: {
					type: "text",
					name: "comment",
					value: null,
					error: null,
					status: "default",
				},
				cancellation: true,
				validation: false,
				extended: false,
			};
			state.systems = {
				list: [],
				extended: false,
			};
			state.pay = {};
			state.backup = {};
			state.payments = {
				badge: "1 389 руб. 09 коп.",
			};
			state.appearance = {
				cancellation: true,
				validation: false,
				extended: false,
			};
			state.shared = {
				cancellation: true,
				validation: false,
				extended: false,
			};
			state.access = {
				password_old: {
					name: "password_old",
					value: null,
					status: "default",
					error: false,
					errorMessage: "Не менее 6 символов, строчные и заглавные буквы, цифры",
				},
				password_new: {
					name: "password_new",
					value: null,
					status: "default",
					error: false,
					errorMessage: "Не менее 6 символов, строчные и заглавные буквы, цифры",
				},
				password_confirm: {
					name: "password_confirm",
					value: null,
					status: "default",
					error: false,
					errorMessage: "Не менее 6 символов, строчные и заглавные буквы, цифры",
				},
				cancellation: true,
				validation: false,
				extended: false,
			};
		},
	},
	actions: {
		fetchProfile(context) {
			context.commit("showPreloader");
			if (this.state.common.notification && this.state.common.notification.type !== "error") {
				context.commit("destroyNotification");
			}

			axios
				.get(`${this.state.common.api}/users/me`, this.state.common.headers)
				.then((response) => {
					context.commit("updateProfileAbout", response.data);
					context.commit("updateProfileSystems", response.data.systems);
					context.commit("updateProfilePay", response.data.cards);
					context.commit("updateProfileCards", response.data.cards);
					context.commit("updateProfileAppearance", response.data.theme);
					context.commit("updateProfileShared", response.data);

					if (response.data.systems.length > 0) {
						context.commit("setObjectsId", response.data.systems[0].id);
					}

					if (response.data.first_entry === true) {
						let payload = {
							type: "password",
							params: {
								password_old: null,
								password_new: null,
								password_confirm: null,
							},
						};

						context.commit("createModal", payload);
					}

					context.commit("hidePreloader");
				})
				.catch((error) => context.commit("handleError", error));
		},
		addProfileAvatar(context, payload) {
			context.commit("showPreloader");

			let formData = new FormData();
			formData.append("files", payload);

			axios
				.post(`${this.state.common.api}/upload`, formData, this.state.common.headers)
				.then((response) => {
					axios
						.put(
							`${this.state.common.api}/users/me`,
							{
								data: {
									avatar: response.data[0].id,
								},
							},
							this.state.common.headers
						)
						.then(() => {
							axios.get(`${this.state.common.api}/users/me`, this.state.common.headers).then((response) => {
								context.commit("updateProfileAbout", response.data);
								context.commit("hidePreloader");
							});
						});
				})
				.catch((error) => context.commit("handleError", error));
		},
		deleteProfileAvatar(context, payload) {
			context.commit("showPreloader");

			axios
				.delete(`${this.state.common.api}/upload/files/${payload.id}`, this.state.common.headers)
				.then(() => {
					axios.get(`${this.state.common.api}/users/me`, this.state.common.headers).then((response) => {
						context.commit("updateProfileAbout", response.data);
						context.commit("hidePreloader");
					});
				})
				.catch((error) => context.commit("handleError", error));
		},
		addProfileContact(context, payload) {
			context.commit("showPreloader");

			let contact = {
				data: {
					last_name: payload.last_name,
					first_name: payload.first_name,
					middle_name: payload.middle_name,
					comment: payload.comment,
					phone: payload.phone,
					email: payload.email,
					from_user: context.state.about.id,
				},
			};

			axios
				.post(`${this.state.common.api}/contacts`, contact, this.state.common.headers)
				.then(() => {
					axios.get(`${this.state.common.api}/users/me`, this.state.common.headers).then((response) => {
						context.commit("updateProfileAbout", response.data);
						context.commit("hidePreloader");
					});
				})
				.catch((error) => context.commit("handleError", error));
		},
		deleteProfileContact(context, payload) {
			context.commit("showPreloader");

			axios
				.delete(`${this.state.common.api}/contacts/${payload.id}`)
				.then(() => {
					axios.get(`${this.state.common.api}/users/me`, this.state.common.headers).then((response) => {
						context.commit("updateProfileAbout", response.data);
						context.commit("hidePreloader");
					});
				})
				.catch((error) => context.commit("handleError", error));
		},
		addProfileUser(context, payload) {
			context.commit("showPreloader");

			let acronym = "";

			if (payload.first_name !== null && payload.last_name !== null) {
				acronym = `${payload.first_name.substr(0, 1)}${payload.last_name.substr(0, 1)}`;
			}

			let user = {
				data: {
					last_name: payload.last_name,
					first_name: payload.first_name,
					middle_name: payload.middle_name,
					status: payload.status,
					phone: payload.phone,
					email: payload.email,
					acronym: acronym,
					issuingAccess: false,
					first_entry: true,
					systems: payload.systems,
				},
			};

			axios
				.post(`${this.state.common.api}/mobile/users/sharedAccess/users`, user, this.state.common.headers)
				.then(() => {
					axios
						.get(`${this.state.common.api}/users/me`, this.state.common.headers)
						.then((response) => {
							context.commit("updateProfileShared", response.data);

							if (response.data.systems.length > 0) {
								context.commit("setObjectsId", response.data.systems[0].id);
							}

							context.commit("hidePreloader");
						})
						.catch((error) => context.commit("handleError", error));
				})
				.catch((error) => context.commit("handleError", error));
		},
		deleteProfileUser(context, payload) {
			context.commit("showPreloader");

			console.log(payload);
		},
		sendProfileChanges(context, payload) {
			context.commit("showPreloader");

			if (payload.section === "about") {
				axios
					.put(
						`${this.state.common.api}/users/me`,
						{
							data: {
								comment: context.state.about.comment.value,
								email_add: context.state.about.email_add.value,
							},
						},
						this.state.common.headers
					)
					.then(() => {
						let endpoints = [];
						let contacts = context.state.about.contacts;

						for (let i = 0; i < contacts.length; i++) {
							if (contacts[i].changed) {
								let obj = {
									url: `${this.state.common.api}/contacts/${contacts[i].id}`,
									payload: {
										data: {
											last_name: contacts[i].last_name.value,
											first_name: contacts[i].first_name.value,
											middle_name: contacts[i].middle_name.value,
											comment: contacts[i].comment.value,
											phone: contacts[i].phone.value,
											email: contacts[i].email.value,
											from_user: context.state.about.id,
										},
									},
								};

								endpoints[i] = obj;
							}
						}

						if (endpoints.length > 0) {
							axios
								.all(
									endpoints.map((endpoint) =>
										axios.put(endpoint.url, endpoint.payload, this.state.common.headers)
									)
								)
								.then(() => {
									axios
										.get(`${this.state.common.api}/users/me`, this.state.common.headers)
										.then((response) => {
											context.commit("updateProfileAbout", response.data);
											context.commit("resetProfileState", "about");
											context.commit("hidePreloader");
										});
								})
								.catch((error) => context.commit("handleError", error));
						} else {
							axios.get(`${this.state.common.api}/users/me`, this.state.common.headers).then((response) => {
								context.commit("updateProfileAbout", response.data);
								context.commit("resetProfileState", "about");
								context.commit("hidePreloader");
							});
						}
					})
					.catch((error) => context.commit("handleError", error));
			}

			if (payload.section === "appearance") {
				axios
					.put(
						`${this.state.common.api}/users/me`,
						{
							data: {
								theme: context.state.appearance.dark ? "dark" : "light",
							},
						},
						this.state.common.headers
					)
					.then(() => {
						//context.commit('updateProfileAppearance', response.data.theme);
						context.commit("resetProfileState", "appearance");
						context.commit("hidePreloader");
					})
					.catch((error) => context.commit("handleError", error));
			}

			if (payload.section === "shared") {
				let users = context.state.shared.users.filter((user) => user.edit === true);

				for (let i = 0; i < users.length; i++) {
					users[i].systems = users[i].systems
						.filter((system) => system.shared === true)
						.map((system) => (system = system.id));
				}

				let endpoints = [];

				for (let i = 0; i < users.length; i++) {
					let user = {
						url: `${this.state.common.api}/mobile/users/sharedAccess/users/${users[i].id}`,
						payload: {
							data: {
								status: users[i].status.value,
								systems: users[i].systems,
							},
						},
					};

					endpoints[i] = user;
				}

				axios
					.all(endpoints.map((endpoint) => axios.put(endpoint.url, endpoint.payload, this.state.common.headers)))
					.then(() => {
						axios
							.get(`${this.state.common.api}/users/me`, this.state.common.headers)
							.then((response) => {
								context.commit("updateProfileShared", response.data);
								context.commit("resetProfileState", "shared");

								if (response.data.systems.length > 0) {
									context.commit("setObjectsId", response.data.systems[0].id);
								}

								context.commit("hidePreloader");
							})
							.catch((error) => context.commit("handleError", error));
					})
					.catch((error) => context.commit("handleError", error));
			}
		},
		addProfileCard(context, payload) {
			context.commit("showPreloader");

			axios
				.post(
					`${this.state.common.api}/cards`,
					{
						data: {
							holder: payload.holder,
							number: +payload.number.split(" ").join(""),
							system: payload.system,
							month: parseInt(payload.month),
							year: +payload.year,
							code: +payload.code,
							from_user: context.state.about.id,
						},
					},
					this.state.common.headers
				)
				.then(() => {
					axios.get(`${this.state.common.api}/users/me`, this.state.common.headers).then((response) => {
						context.commit("updateProfileCards", response.data.cards);
						context.commit("hidePreloader");
					});
				})
				.catch((error) => context.commit("handleError", error));
		},
		deleteProfileCard(context, payload) {
			context.commit("showPreloader");

			axios
				.delete(`${this.state.common.api}/cards/${payload.id}`)
				.then(() => {
					axios.get(`${this.state.common.api}/users/me`, this.state.common.headers).then((response) => {
						context.commit("updateProfileCards", response.data.cards);
						context.commit("hidePreloader");
					});
				})
				.catch((error) => context.commit("handleError", error));
		},
		changeProfilePassword(context, payload) {
			context.commit("showPreloader");

			axios
				.post(
					`${this.state.common.api}/users/changePassword`,
					{
						user_id: context.state.about.id,
						password_old: payload.password_old,
						password_new: payload.password_new,
						password_confirm: payload.password_confirm,
					},
					this.state.common.headers
				)
				.then((response) => {
					context.commit("hidePreloader");

					if (response.status === 200) {
						let payload = {
							type: "password",
						};

						context.commit("resetProfileState", "access");
						context.commit("createPopup", payload);
					}
				})
				.catch((error) => context.commit("handleError", error));
		},
	},
};
