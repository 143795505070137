<template>
	<div class="nothing">
		<main class="nothing__main">
			<h1 class="nothing__headline">Страница не существует</h1>
		</main>
	</div>
</template>

<script>
export default {
	name: "Nothing",
};
</script>

<style lang="scss" scoped>
.nothing {
	width: 100%;
	margin: 0 auto;
	max-width: 1200px;

	&__headline {
		font-family: "Avenir";
		font-size: 30px;
		font-weight: 400;
	}
}
</style>
