<template>
	<main class="objects" :data-id="about.id">
		<div class="objects__content">
			<Notification />
			<template v-if="navbar.length > 0">
				<div
					:class="
						notification && notification.type == 'error'
							? 'objects__navbar objects__navbar_error'
							: 'objects__navbar'
					"
				>
					<ul class="objects__navbar-list">
						<li
							v-for="item in navbar"
							:class="[
								item.isActive ? 'objects__navbar-item objects__navbar-item_current' : 'objects__navbar-item',
							]"
							:data-id="item.id"
							:key="item.id"
							@click="setActiveItem"
						>
							<div class="objects__navbar-avatar"></div>
							<div class="objects__navbar-info">
								<span class="objects__navbar-name">{{ item.name }}</span>
								<span class="objects__navbar-feature">
									{{ item.contract.value }}
								</span>
							</div>
						</li>
					</ul>
				</div>
				<section :class="notification && notification.type == 'error' ? 'about about_error' : 'about'">
					<div class="about__header" @click="toggleSection('about')">
						<div class="about__wrapper">
							<div class="about__name">
								<div class="about__title">Имя объекта</div>
								<Input
									:type="'text'"
									:name="about.name.name"
									:value="about.name.value"
									:status="about.name.status"
									@paste="handleChanges($event, about.name.element)"
									@input="handleChanges($event, about.name.element)"
									@change="handleChanges($event, about.name.element)"
									@click.stop="false"
								/>
								<span class="about__error" v-if="about.name.error">{{ about.name.errorText }}</span>
							</div>
							<div class="about__address">
								<div class="about__title">Адрес объекта</div>
								<Input :type="'text'" :value="about.address" :disabled="true" />
							</div>
							<div class="about__service">
								<div class="about__title">Дата планового ТО</div>
								<Input :type="'text'" :value="this.convertDate(about.date_maintenance_plan)" :disabled="true" />
							</div>
							<div class="about__mileage">
								<div class="about__title">Моточасы</div>
								<Input :type="'text'" :value="about.pump_hours" :disabled="true" />
							</div>
						</div>
						<div class="about__control">
							<div class="about__info">
								<div :class="`about__state about__state_${about.state.value}`">
									{{ about.state.text }}
								</div>
								<template v-if="about.state.value === 'disabled'">
									<Switch :paint="'blue'" :status="false" :disabled="true" @click.stop="false" />
								</template>
								<template v-else>
									<Switch
										:name="'active_user'"
										:status="about.active_user"
										:paint="'blue'"
										@change.prevent="handleChanges($event, 'switch')"
									/>
								</template>
							</div>
							<button class="about__button" @click.stop="toggleSection('about')">
								<span
									:class="[
										about.extended ? 'about__button-icon about__button-icon_rotate' : 'about__button-icon',
									]"
								></span>
							</button>
						</div>
					</div>
					<div class="about__body" v-show="about.extended">
						<div class="about__contract">
							Договор {{ about.contract.value }} от
							{{ this.convertDate(about.contract.date) }}
						</div>
						<div class="about__params">
							<div class="about__param">
								<div class="about__title">Конфигурация системы</div>
								<template v-if="about.type === 'pro'">
									<Input :type="'text'" :value="`Airwet pro`" :disabled="true" />
								</template>
								<template v-if="about.type === 'home'">
									<Input
										:type="'text'"
										:value="`Airwet ${about.type} ${about.system_type !== null ? about.system_type : ''} ${
											zones.length > 0 ? this.declOfNum(zones.length) : ''
										}`"
										:disabled="true"
									/>
								</template>
								<template v-if="about.type === 'light'">
									<Input
										:type="'text'"
										:value="`Airwet ${about.type} ${zones.length > 0 ? this.declOfNum(zones.length) : ''}`"
										:disabled="true"
									/>
								</template>
							</div>
							<div class="about__param">
								<div class="about__title">Дата запуска</div>
								<Input :type="'text'" :value="this.convertDate(about.date_start)" :disabled="true" />
							</div>
							<div class="about__param">
								<div class="about__title">Гарантия до</div>
								<Input :type="'text'" :value="this.convertDate(about.date_warranty)" :disabled="true" />
							</div>
						</div>
						<div class="about__vacation" v-if="about.vacation">
							<div class="about__vacation-info">
								<div class="about__vacation-title">Отпуск</div>
								<div class="about__vacation-description">Активировать режим отпуска</div>
							</div>
							<div class="about__vacation-control">
								<Switch
									name="vacation"
									:status="about.vacation.active_user"
									:paint="'blue'"
									@change="handleChanges($event, 'switch')"
								/>
							</div>
						</div>
						<section class="documents" v-if="Array.isArray(about.documents) && about.documents.length > 0">
							<div class="documents__header" v-on:click="toggleSubSection">
								<div class="documents__info">
									<div class="documents__name">ДОКУМЕНТЫ</div>
									<div class="documents__description">все документы вашей системы</div>
								</div>
								<div class="documents__control">
									<button class="documents__button" v-on:click.stop="toggleSubSection">
										<span class="documents__button-icon"></span>
									</button>
								</div>
							</div>
							<div class="documents__body">
								<div class="documents__preview">
									<div
										class="documents__document"
										v-for="document in about.documents"
										:data-id="document.id"
										:key="document.id"
									>
										<div class="documents__document-info">
											<div class="documents__document-name">
												{{ document.name }}.{{ document.file.ext.slice(1) }}
											</div>
											<div class="documents__document-size">{{ document.file.size }} Кб</div>
										</div>
										<div class="documents__document-control">
											<Button
												:type="'action'"
												:value="'download'"
												:paint="'gray'"
												@click="downloadDocument(document)"
											/>
										</div>
									</div>
								</div>
							</div>
						</section>
						<div class="about__footer">
							<Button
								:type="'text'"
								:value="'Отмена'"
								:paint="'gray'"
								:disabled="about.cancellation"
								@click="cancelChanges($event, 'about')"
							/>
							<Button
								:type="'text'"
								:value="'Сохранить изменения'"
								:paint="'blue'"
								:disabled="!about.validation"
								@click="sendChanges('about')"
							/>
						</div>
					</div>
				</section>
				<div
					:class="
						notification && notification.type == 'error'
							? 'objects__zones objects__zones_error'
							: 'objects__zones'
					"
				>
					<Zone
						v-for="zone in zones"
						:zone="zone"
						:key="zone.id"
						@znclick="handleZone"
						@zninput="handleZone"
						@znchange="handleZone"
						@zncollapse="collapseZone"
						@znapply="changeZone"
						@zncancel="cancelZone"
						v-click-outside="commonClick"
					/>
				</div>
			</template>
		</div>
	</main>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import Notification from "../layout/Notification";

import Button from "../components/Button";
import Input from "../components/Input";
import Switch from "../components/Switch";
import Zone from "../components/Zone";

export default {
	name: "Objects",
	components: {
		Notification,
		Button,
		Input,
		Switch,
		Zone,
	},
	computed: {
		...mapGetters({
			notification: "getCommonNotification",
			navbar: "getObjectsNavbar",
			about: "getObjectsAbout",
			zones: "getObjectsZones",
		}),
	},
	methods: {
		...mapMutations([
			"toggleObjectsSection",
			"setObjectsActiveZone",
			"extendObjectsZone",
			"collapseObjectsZone",
			"handleObjectsChanges",
			"handleObjectsZone",
			"cancelObjectsChanges",
			"resetObjectsState",
			"createModal",
			"createPopup",
			"createViewer",
			"updateObjectsZonesSocket",
		]),
		...mapActions(["fetchObjects", "downloadObjectsDocument", "sendObjectsChanges", "changeObjectsZone"]),
		toggleSection(section) {
			this.toggleObjectsSection(section);
		},
		toggleSubSection(event) {
			let section = event.target.closest("section");
			let className = section.classList[0];
			let sectionBody = section.querySelector(`.${className}__body`);
			let sectionButton = section.querySelector(`.${className}__button-icon`);

			if (getComputedStyle(sectionBody).display === "none") {
				sectionBody.style.display = "block";
				sectionButton.className = `${className}__button-icon ${className}__button-icon_rotate`;
			} else if (getComputedStyle(sectionBody).display === "block") {
				sectionBody.style.display = "none";
				sectionButton.className = `${className}__button-icon`;
			}
		},
		convertDate(timestamp) {
			if (timestamp !== null) {
				let newDate = new Date(timestamp * 1000);
				let year = newDate.getFullYear();
				let month = newDate.getMonth() + 1;
				if (month < 10) month = "0" + month;
				let day = newDate.getDate();
				if (day < 10) day = "0" + day;
				let date = `${day}.${month}.${year}`;
				return date;
			}
		},
		declOfNum(number) {
			number = Math.abs(number) % 100;
			let n1 = number % 10;
			let arr = ["зона", "зоны", "зон"];

			if (number > 10 && number < 20) return `${number} ${arr[2]}`;
			if (n1 > 1 && n1 < 5) return `${number} ${arr[1]}`;
			if (n1 == 1) return `${number} ${arr[0]}`;
			return `${number} ${arr[2]}`;
		},

		setActiveItem(event) {
			let payload = {
				id: +event.target.closest(".objects__navbar-item").getAttribute("data-id"),
			};

			this.$router.push(`/objects/${payload.id}`);
			this.fetchObjects(payload.id);
		},

		downloadDocument(document) {
			this.downloadObjectsDocument(document);
		},
		commonClick(event) {
			if (!event.target.closest(".zone")) {
				this.resetObjectsState();
			}
		},
		handleChanges(event, element) {
			let payload = {};

			if (element === "input") {
				payload = {
					event: event.type,
					name: event.target.getAttribute("name"),
					value: event.target.value,
				};
			}

			if (element === "switch") {
				payload = {
					value: event.target.checked,
					name: event.target.getAttribute("name"),
					event: "change",
				};
			}

			payload.element = element;
			payload.section = event.target.closest("section").className;

			this.handleObjectsChanges(payload);
		},
		cancelChanges(event, param) {
			let payload = {
				param: param,
			};
			//TODO: Пересмотреть!!!
			if (param === "zone") {
				payload.id = +event.target.closest(".zone").getAttribute("data-id");
			}

			this.cancelObjectsChanges(payload);
		},
		sendChanges(payload) {
			this.sendObjectsChanges(payload);
		},

		handleZone(payload) {
			this.setObjectsActiveZone(payload);
			this.extendObjectsZone(payload);

			this.handleObjectsZone(payload);
		},
		collapseZone(payload) {
			this.collapseObjectsZone(payload);
		},
		cancelZone(payload) {
			this.cancelObjectsChanges(payload);
		},
		changeZone(payload) {
			this.changeObjectsZone(payload);
		},
	},
	created() {
		this.fetchObjects(this.$route.params.id);

		/* this.$soketio.on(`system/${this.$route.params.id}/state`, (data) => {
			if (this.$route.params.id == data.id) {
				this.updateObjectsZonesSocket(data);
			}
		}); */
	},
};
</script>

<style lang="scss" scoped>
.objects {
	position: relative;
	width: 100%;

	@include mediaquery(sm) {
		width: calc(100% - 75px);
	}

	&__content {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;
		position: relative;
		padding-bottom: 50px;

		@include mediaquery(sm) {
			padding-bottom: 100px;
		}
	}

	&__navbar {
		width: 100%;
		margin-bottom: 20px;

		&_error {
			filter: blur(2px);
			pointer-events: none;
		}

		&-list {
			padding: 0;
			margin: 0;
			list-style: none;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;

			@include mediaquery(md) {
				flex-direction: row;
				margin-left: -5px;
				margin-right: -5px;
				margin-top: -5px;
				margin-bottom: -10px;
				width: calc(100% + 10px);
			}
		}

		&-item {
			@include base;

			display: flex;
			width: 100%;
			border: 2px solid $colorMainLight;
			padding: 10px;
			margin-bottom: 10px;
			cursor: pointer;

			&:last-of-type {
				margin-bottom: 0;
			}

			@include mediaquery(md) {
				width: calc(50% - 10px);
				margin: 5px;

				&:last-of-type {
					margin-bottom: 5px;
				}
			}

			@include mediaquery(lg) {
				width: calc(33.333333% - 10px);
			}

			@include mediaquery(xl) {
				width: calc(25% - 10px);
			}

			&_current {
				border: 2px solid #41c6eb;

				.objects__navbar-avatar {
					background-color: #fff;
				}

				.objects__navbar-name {
					color: #222325;
				}
			}
		}

		&-avatar {
			height: 40px;
			width: 40px;
			min-width: 40px;
			height: 40px;
			border-radius: 2px;
			background-color: #dddddd;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&-info {
			display: flex;
			flex-direction: column;
			margin-left: 10px;
			overflow: hidden;
		}

		&-name {
			font-weight: 500;
			font-size: 14px;
			color: #222325;
			text-transform: uppercase;
		}

		&-feature {
			margin-top: 5px;
			font-size: 10px;
			color: #9b9b9b;
			width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&__zones {
		width: 100%;
		display: flex;
		flex-direction: column;

		&_error {
			filter: blur(2px);
			pointer-events: none;
		}

		@include mediaquery(md) {
			flex-wrap: wrap;
			flex-direction: row;
			align-items: flex-start;
			margin-left: -5px;
			margin-right: -5px;
			margin-top: -10px;
			margin-bottom: 20px;
			width: calc(100% + 10px);
		}
	}

	.about {
		@include base;

		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 20px;
		margin-bottom: 15px;

		&_error {
			filter: blur(2px);
			pointer-events: none;
		}

		&__header {
			display: flex;
			justify-content: space-between;
			width: 100%;
			padding-bottom: 10px;
			cursor: pointer;
		}

		&__wrapper {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 65%;

			@include mediaquery(xl) {
				flex-wrap: nowrap;
				justify-content: flex-start;
				width: auto;
				flex-direction: row;
				align-items: center;
			}
		}

		&__name {
			width: 100%;
			margin-bottom: 10px;
			position: relative;

			@include mediaquery(lg) {
				width: 200px;
			}

			@include mediaquery(xl) {
				margin-right: 10px;
				margin-bottom: 0;
			}
		}

		&__address {
			width: 100%;
			margin-bottom: 10px;
			position: relative;

			@include mediaquery(xl) {
				width: 310px;
				margin-bottom: 0;
				margin-right: 10px;
			}
		}

		&__service {
			width: 100%;
			margin-bottom: 10px;
			position: relative;

			@include mediaquery(md) {
				width: calc(50% - 5px);
			}

			@include mediaquery(xl) {
				width: 210px;
				margin-bottom: 0;
				margin-right: 10px;
			}
		}

		&__mileage {
			width: 100%;
			margin-bottom: 10px;
			position: relative;

			@include mediaquery(md) {
				width: calc(50% - 5px);
			}

			@include mediaquery(xl) {
				width: 210px;
				margin-bottom: 0;
			}
		}

		&__info {
			display: flex;
			flex-direction: column;
			align-items: flex-end;

			@include mediaquery(md) {
				flex-direction: row;
				align-items: center;
			}
		}

		&__state {
			@include state;
			@include mediaquery(md) {
				margin: 0 10px;
			}
		}

		&__control {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-top: 15px;
			width: 35%;
			margin-left: 10px;

			@include mediaquery(sm) {
				width: 30%;
			}
		}

		&__button {
			position: relative;
			background: none;
			padding: 0;
			border: 0;
			height: 11px;
			width: 11px;
			cursor: pointer;
			margin-left: 10px;

			&-icon {
				position: absolute;
				left: 2px;
				top: 0px;
				width: 7px;
				height: 7px;
				border: solid #bcbcbc;
				border-width: 0 2px 2px 0;
				transform: rotate(45deg);

				&_rotate {
					border-color: #222325;
					top: 3px;
					transform: rotate(225deg);
				}
			}
		}

		&__contract {
			font-size: 12px;
			font-weight: 500;
			margin: 5px 0 11px;
		}

		&__params {
			display: flex;
			flex-wrap: wrap;
			padding-bottom: 20px;

			@include mediaquery(sm) {
				margin-left: -5px;
				margin-right: -5px;
			}
		}

		&__param {
			width: 100%;
			margin-bottom: 10px;

			@include mediaquery(sm) {
				width: calc(50% - 10px);
				margin: 5px;
			}

			@include mediaquery(lg) {
				width: calc(25% - 10px);
			}
		}

		&__title {
			@include title;
		}

		&__error {
			position: absolute;
			left: 0;
			top: 100%;
			font-size: 9px;
			color: #ff8068;
		}

		&__vacation {
			@include base;

			width: 100%;
			padding: 10px 15px;
			margin-bottom: 10px;
			display: flex;
			justify-content: space-between;

			&-title {
				font-size: 12px;
				font-weight: 500;
				text-transform: uppercase;
			}

			&-description {
				font-size: 10px;
				margin-top: 5px;
			}

			&-control {
				display: flex;
				align-items: center;
			}
		}

		&__features {
			border-top: 1px solid #e1e1e1;
			border-bottom: 1px solid #e1e1e1;
			padding: 20px 0;
		}

		&__tabs {
			display: flex;
			justify-content: space-between;
		}

		&__feature {
			width: calc(100% / 3 - 10px);
			padding: 10px 15px;
			display: flex;
			justify-content: space-between;

			&-title {
				font-size: 12px;
				font-weight: 500;
				text-transform: uppercase;
			}

			&-description {
				font-size: 10px;
				margin-top: 5px;
			}

			&-control {
				display: flex;
				align-items: center;
			}
		}

		&__maintenance {
			padding-top: 20px;
		}

		&__documents {
			padding-top: 20px;
			display: flex;

			&-column {
				width: 50%;

				&:first-of-type {
					padding-right: 10px;
				}
			}
		}

		&__document {
			margin-bottom: 10px;
			&-wrapper {
				display: flex;
			}

			input {
				margin-right: 10px;
			}

			&-control {
				display: flex;
			}

			button {
				&:not(:last-of-type) {
					margin-right: 10px;
				}
			}
		}

		&__footer {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding: 20px 0 0;

			button {
				width: 100%;

				&:first-of-type {
					margin-bottom: 10px;
				}
			}

			@include mediaquery(sm) {
				flex-direction: row;

				button {
					width: auto;
					margin-bottom: 0;

					&:first-of-type {
						margin-right: 10px;
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.maintenance {
		@include base;

		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 15px;
		margin-bottom: 10px;

		&__header {
			display: flex;
			justify-content: space-between;
			width: 100%;
			cursor: pointer;
		}

		&__name {
			font-size: 12px;
			font-weight: 500;
			text-transform: uppercase;
		}

		&__description {
			font-size: 10px;
			margin-top: 5px;
		}

		&__control {
			display: flex;
			align-items: center;
		}

		&__button {
			position: relative;
			background: none;
			padding: 0;
			border: 0;
			height: 11px;
			width: 11px;
			cursor: pointer;
			margin-left: 10px;

			&-icon {
				position: absolute;
				left: 2px;
				top: 0px;
				width: 7px;
				height: 7px;
				border: solid #bcbcbc;
				border-width: 0 2px 2px 0;
				transform: rotate(45deg);

				&_rotate {
					border-color: #222325;
					top: 3px;
					transform: rotate(225deg);
				}
			}
		}

		&__body {
			display: none;
		}

		&__blocks {
			display: flex;
			flex-direction: column;
			margin-top: 20px;

			@include mediaquery(md) {
				flex-direction: row;
				flex-wrap: wrap;
				margin-left: -10px;
				margin-right: -10px;
				padding-left: 5px;
				padding-right: 5px;
			}
		}

		&__block {
			width: 100%;
			margin-bottom: 10px;

			&:last-of-type {
				margin-bottom: 0;
			}

			@include mediaquery(md) {
				width: calc(50% - 10px);
				margin: 5px;
			}
		}

		&__title {
			font-size: 9px;
			margin-bottom: 6px;
			color: #9b9b9b;
		}

		&__wrapper {
			display: flex;
			flex-direction: column;

			@include mediaquery(sm) {
				flex-direction: row;
			}

			input {
				margin-bottom: 10px;

				@include mediaquery(sm) {
					margin-right: 10px;
					margin-bottom: 0;
				}
			}
		}
	}

	.shared {
		@include base;

		display: flex;
		flex-direction: column;
		width: 100%;
		margin-bottom: 10px;
		padding: 15px;

		&__header {
			display: flex;
			justify-content: space-between;
			width: 100%;
			cursor: pointer;
		}

		&__name {
			font-size: 12px;
			font-weight: 500;
			text-transform: uppercase;
		}

		&__description {
			font-size: 10px;
			margin-top: 5px;
		}

		&__control {
			display: flex;
			align-items: center;
		}

		&__button {
			position: relative;
			background: none;
			padding: 0;
			border: 0;
			height: 11px;
			width: 11px;
			cursor: pointer;
			margin-left: 10px;

			&-icon {
				position: absolute;
				left: 2px;
				top: 0px;
				width: 7px;
				height: 7px;
				border: solid #bcbcbc;
				border-width: 0 2px 2px 0;
				transform: rotate(45deg);

				&_rotate {
					border-color: #222325;
					top: 3px;
					transform: rotate(225deg);
				}
			}
		}

		&__body {
			display: none;
		}

		&__preview {
			padding-top: 10px;
			display: flex;
			flex-wrap: wrap;
			margin: 0 -5px;
			margin-top: -5px;
		}

		&__add {
			@include base;

			padding: 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			margin: 5px;

			&-title {
				font-weight: 500;
				font-size: 12px;
				margin-left: 18px;
				color: #2b1a0a;
			}
		}

		&__user {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;
			width: 100%;
			margin: 5px;
			margin-bottom: 10px;

			.shared__block {
				width: 100%;
				margin-bottom: 10px;
			}

			@include mediaquery(md) {
				justify-content: flex-start;
				align-items: flex-end;

				.shared__block {
					width: calc(33.333333% - 22px);
					margin-right: 10px;
				}

				button {
					margin-bottom: 10px;
				}
			}

			@include mediaquery(xl) {
				flex-wrap: nowrap;

				.shared__block {
					width: calc(25% - 19px);
					margin-right: 10px;
					margin-bottom: 0;
				}

				&:last-of-type {
					margin-bottom: 0;
				}

				button {
					margin-bottom: 0;
				}
			}
		}

		&__block {
			position: relative;
		}

		&__title {
			@include title;
		}

		&__error {
			position: absolute;
			left: 0;
			top: 100%;
			font-size: 9px;
			color: #ff8068;
		}
	}

	.documents {
		@include base;

		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 15px;

		&__header {
			display: flex;
			justify-content: space-between;
			width: 100%;
			cursor: pointer;
		}

		&__name {
			font-size: 12px;
			font-weight: 500;
			text-transform: uppercase;
		}

		&__description {
			font-size: 10px;
			margin-top: 5px;
		}

		&__control {
			display: flex;
			align-items: center;
		}

		&__button {
			position: relative;
			background: none;
			padding: 0;
			border: 0;
			height: 11px;
			width: 11px;
			cursor: pointer;
			margin-left: 10px;

			&-icon {
				position: absolute;
				left: 2px;
				top: 0px;
				width: 7px;
				height: 7px;
				border: solid #bcbcbc;
				border-width: 0 2px 2px 0;
				transform: rotate(45deg);

				&_rotate {
					border-color: #222325;
					top: 3px;
					transform: rotate(225deg);
				}
			}
		}

		&__body {
			display: none;
		}

		&__preview {
			padding-top: 10px;
			display: flex;
			flex-wrap: wrap;
			margin: 0 -5px;
			margin-top: -5px;
		}

		&__document {
			@include base;

			padding: 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			margin: 5px;

			@include mediaquery(md) {
				width: calc(50% - 10px);
			}

			&-icon {
				width: 46px;
			}

			&-wrapper {
				display: flex;
				align-items: center;
			}

			&-info {
				display: flex;
				align-items: center;
				margin-left: 17px;
			}

			&-name {
				font-size: 12px;
				font-weight: 500;
				color: #2b1a0a;
			}

			&-size {
				font-size: 10px;
				color: #2b1a0a;
				margin-left: 20px;
			}

			&-control {
				display: flex;

				button {
					margin-left: 10px;
				}
			}

			&-link {
				height: 36px;
				min-width: 36px;
				padding: 6px;
				border-radius: 6px;
				box-shadow: $shadowLight;
				border: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				user-select: none;

				@media (hover: hover) {
					&:hover {
						background-color: #fff;
					}
				}
			}

			&-icon {
				width: 20px;
				opacity: 0.5;
			}
		}

		&__title {
			@include title;
		}

		&__wrapper {
			display: flex;
			flex-direction: column;

			@include mediaquery(sm) {
				flex-direction: row;
			}

			input {
				margin-bottom: 10px;

				@include mediaquery(sm) {
					margin-right: 10px;
					margin-bottom: 0;
				}
			}
		}
	}
}
</style>
